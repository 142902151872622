import { Controller } from "@hotwired/stimulus"
import fetchWithCSRF from "../utils/fetch_with_csrf"
import encodeFormData from "../utils/encode_form_data"
import { throwUnlessOk, handleError } from "../utils/errors"
import ga from "../utils/ga"

export default class extends Controller {
  static targets = ["formMethod", "form", "itemId"]

  toggleBookmark(event) {
    event.preventDefault()
    event.stopImmediatePropagation() // this stops Turbo from making the request.
    // For some reason data-turbo="false" doesn't work. https://github.com/hotwired/turbo/pull/3

    if (this.element.dataset.fetchInProgress !== "true") {
      const body = encodeFormData(this.formTarget)

      this.element.setAttribute("data-fetch-in-progress", "true")

      fetchWithCSRF(this.formTarget.action, { method: "POST", body })
        .then(throwUnlessOk)
        .then((response) => this.handleResponse(response))
        .catch((error) => handleError(error))
        .finally(() => this.element.setAttribute("data-fetch-in-progress", "false"))
    }
  }

  handleResponse(response) {
    if (this.element.dataset.bookmarked === "true") {
      this.element.dataset.bookmarked = "false"
      this.formMethodTarget.value = "post"
      this.sendAnalyticsEvent("collection: removed")
    } else {
      this.formMethodTarget.value = "delete"
      this.element.dataset.bookmarked = "true"
      this.sendAnalyticsEvent("collection: added")
    }
  }

  sendAnalyticsEvent(eventLabel) {
    ga("send", {
      hitType: "event",
      eventCategory: "Item",
      eventAction: "click",
      eventValue: this.itemIdTarget.value,
      eventLabel,
    })
  }
}
