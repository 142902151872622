import { Controller } from "@hotwired/stimulus"
import VolumeStore from "../stores/volume_store"
import ga from "../utils/ga"

export default class extends Controller {
  static targets = ["rangeInput"]
  static classes = ["muted"]
  static values = { gaCategory: String }

  connect() {
    this.lastReportedVolume = VolumeStore.volume
    this.updateUI()
  }

  toggleMute() {
    const isMuted = VolumeStore.toggleMute()

    if (isMuted) {
      this.sendAnalyticsEvent("toggle;mute")
    } else {
      this.sendAnalyticsEvent("toggle;unmute")
    }

    this.lastReportedVolume = VolumeStore.volume
    this.updateUI()
  }

  adjustVolume(event) {
    VolumeStore.volume = parseInt(event.target.value)
    this.updateUI()
  }

  updateUI() {
    if (this.hasRangeInputTarget) {
      this.rangeInputTarget.value = VolumeStore.volume
      this.rangeInputTarget.setAttribute("aria-valuenow", VolumeStore.volume)
    }

    if (VolumeStore.volume === 0) {
      this.element.classList.add(this.mutedClass)
    } else {
      this.element.classList.remove(this.mutedClass)
    }
  }

  reportVolumeChanges() {
    if (VolumeStore.volume < this.lastReportedVolume) {
      this.sendAnalyticsEvent("slide;volume down")
    } else if (VolumeStore.volume > this.lastReportedVolume) {
      this.sendAnalyticsEvent("slide;volume up")
    }

    this.lastReportedVolume = VolumeStore.volume
  }

  sendAnalyticsEvent(eventAction) {
    ga("send", {
      hitType: "event",
      eventCategory: this.gaCategoryValue,
      eventAction,
    })
  }
}
