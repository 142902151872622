// This is a special GA queue, used when we want to send GA events during a stimulus connect()
// We queue up the events until the page is fully loaded, and then we send these events
// AFTER the page load event happens.
// This queue is started in application.js

import ga from "./ga"

class GAQueue {
  constructor() {
    this._queue = []
    this._processing = false
    this._paused = true // start queue paused by default, wait for page load before sending these queued events.
  }

  pause() {
    this._paused = true
  }

  unpause() {
    this._paused = false
    this.process()
  }

  push(commandArguments) {
    this._queue.push(commandArguments)
  }

  process() {
    // flag to ensure this is not ever done in parallel to ensure the queue stays in order.
    if (this._processing) return
    if (this._paused) return

    this._processing = true
    while (!this._paused && this._queue.length > 0) {
      this.processArgs(this._queue.shift())
    }
    this._processing = false
  }

  processArgs(args) {
    ga(...args)
  }
}

const gaQueue = (...args) => {
  // Push items into a queue, but don't process them straight away.
  // We process them in application.js when turbo does the load event.

  // Ignore GA commands that are pushed our way when we're in stimulus preview mode.
  // Wait a few more milliseconds for the non-cached page to load before accepting those events.
  // This is mainly a problem when we send GA events during a stimulus connect() or initialize() method.
  if (!document.documentElement.hasAttribute("data-turbo-preview")) {
    gaQueue.queue.push(args)
    gaQueue.queue.process()
  }
}

gaQueue.queue = new GAQueue()

export default gaQueue
