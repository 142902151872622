import { Controller } from "@hotwired/stimulus"
import { setCookieWithConsent, getCookieWithConsent, removeCookieWithDefaultOptions } from "../utils/cookies"
import { Consent } from "../utils/consent"

const showGuiToggle = "SHOW_EXPERIMENTS_GUI"

export default class extends Controller {
  static classes = ["visibleButton"]

  connect() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const paramValue = urlSearchParams.get(showGuiToggle)

    if (paramValue === "true") {
      setCookieWithConsent(showGuiToggle, "true", Consent.necessary)
    } else if (paramValue === "false") {
      const optionsForRemoval = { path: "/" }
      removeCookieWithDefaultOptions(showGuiToggle, optionsForRemoval)
    }
  }

  display() {
    const guiCookie = getCookieWithConsent(showGuiToggle, Consent.necessary)

    if (guiCookie) {
      this.element.classList.add(this.visibleButtonClass)
    }
  }
}
