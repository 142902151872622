import { Controller } from "@hotwired/stimulus"
import { throwUnlessOk, handleError } from "../utils/errors"
import VolumeStore from "../stores/volume_store"
import { getMinutesSecondsTime } from "../utils/time"

export default class extends Controller {
  static targets = [
    "playButton",
    "pauseButton",
    "audioElement",
    "audioFileSource",
    "canvasWrapper1",
    "canvas1",
    "canvasWrapper2",
    "canvas2",
    "canvasWrapper3",
    "canvas3",
    "loadingSpinner",
    "playBar",
    "playBarCurrentTime",
    "waveformContainer",
  ]

  connect() {
    if ("ResizeObserver" in window) {
      // Redraw waveform on resize (but don't throw an error if it's an old browser without ResizeObserver)
      const resizeObserver = new ResizeObserver((entries) => this.redrawProgress())
      resizeObserver.observe(this.element)
    }

    this.bufferProgress = 0
    this.waveformData = null
    this.playing = false
    this.playBarProgress = 0
    this.audioResetTimeout = null
    this.observer = new IntersectionObserver(this.onIntersection.bind(this))
    this.observer.observe(this.element)
    this.setVolumeFromStore()
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }

  onIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadWaveformData()
        this.preloadMetadata()
        this.observer.unobserve(this.element)
      }
    })
  }

  loadWaveformData() {
    fetch(this.element.dataset.waveform)
      .then(throwUnlessOk)
      .then((response) => response.json())
      .then((json) => this.handleWaveformData(json))
      .catch(handleError)
  }

  preloadMetadata() {
    this.audioElementTarget.preload = "metadata"
  }

  play() {
    this.discardAudioReset()
    this.setVolumeFromStore()
    if (this.playBarProgress > 0 && this.playBarProgress < 0.99 && this.audioElementTarget.duration) {
      // try to recover progress
      this.audioElementTarget.currentTime = this.playBarProgress * this.audioElementTarget.duration
    }
    this.playPromise = this.audioElementTarget.play()
    this.playing = true
    if (this.playPromise !== undefined) {
      this.playPromise
        .then((_) => {
          this.audioElementTarget.dataset.playing = "true"
        })
        .catch(handleError)
    }
    this.setButtonDisplayState()
    window.requestAnimationFrame(this.onAudioProgress())
    this.emitActivePlayerChangeEvent()
  }

  sampleData(response) {
    const form = { max: 1, samples: [] }
    for (let i = 0; i < response.length; i++) {
      const sample = response.data[i * 2 + 1] - response.data[i * 2]
      form.samples.push(sample)
      if (sample > form.max) {
        form.max = sample
      }
    }
    return form
  }

  setVolumeFromStore() {
    this.audioElementTarget.volume = VolumeStore.volumeAsDecimal
  }

  emitActivePlayerChangeEvent() {
    const detail = { player: this.audioElementTarget }
    const event = new CustomEvent("activePlayerChange", { detail })
    window.dispatchEvent(event)
  }

  pauseForNewActivePlayer(event) {
    if (this.audioElementTarget === event.detail.player) return
    this.playing && this.pause()
  }

  onAudioProgress() {
    const context = this

    return function () {
      if (context.playing) {
        context.setButtonDisplayState()
        if (!context.dragging) {
          context.playBarProgress = context.audioElementTarget.currentTime / context.audioElementTarget.duration
          context.redrawProgress()
        }
        window.requestAnimationFrame(context.onAudioProgress(context))
      }
    }
  }

  onAudioEnded = () => this.pause()

  onAudioBuffer() {
    if (this.audioElementTarget.buffered.length > 0 && this.audioElementTarget.duration) {
      this.bufferProgress =
        this.audioElementTarget.buffered.end(this.audioElementTarget.buffered.length - 1) /
        this.audioElementTarget.duration

      if (this.bufferProgress > 1) {
        this.bufferProgress = 1
      }
    }

    this.redrawProgress()
  }

  handleWaveformData(json) {
    this.waveformData = this.sampleData(json)
    this.redrawProgress()
  }

  redrawProgress() {
    if (this.hasCanvas1Target) {
      this.drawWaveForm(this.waveformData, this.canvas1Target, this.canvasWrapper1Target, "hsl(0, 0%, 80%)", 1)
    }

    if (this.hasCanvas2Target) {
      this.drawWaveForm(
        this.waveformData,
        this.canvas2Target,
        this.canvasWrapper2Target,
        "hsl(0, 0%, 60%)",
        this.bufferProgress,
      )
    }
    if (this.hasCanvas3Target) {
      this.drawWaveForm(
        this.waveformData,
        this.canvas3Target,
        this.canvasWrapper3Target,
        "hsl(87, 58%, 60%)",
        this.playBarProgress,
      )
    }

    this.playBarTarget.style.display = this.playBarProgress > 0 ? "block" : "none"
    this.playBarTarget.style.width = `${this.playBarProgress * 100}%`
    if (this.playBarProgress === 0 || this.audioElementTarget.currentTime > 0) {
      this.playBarCurrentTimeTarget.textContent = getMinutesSecondsTime(this.audioElementTarget.currentTime)
    }
  }

  drawWaveForm(waveformData, canvas, canvasWrapper, color, progress) {
    if (!waveformData) return

    const context = canvas.getContext("2d")

    // sometimes context is null
    // it's causing rollbars for certain search results,
    // apparently context is null if getContext has been called previously on that canvas with a different argument,
    // but i can't see where or why that would be happening
    // or when it doesn't know that drawing type,
    // but everything that supports any getContext supports getContext("2d"),
    // so lets just skip drawing sometimes
    if (!context) return

    const parent = canvasWrapper.parentNode
    canvas.style.width = `${parent.clientWidth}px`
    canvas.width = waveformData.samples.length
    canvasWrapper.style.width = `${progress * 100}%`

    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height)

    // Pre-calculate some values
    const yBaseline = canvas.height / 2
    const yMultiplier = yBaseline / waveformData.max

    // Draw the dividing middle line
    context.lineWidth = 0.1
    context.beginPath()
    context.moveTo(0, yBaseline)
    context.lineTo(canvas.width, yBaseline)
    context.stroke()

    // Draw the top part of the waveform
    context.fillStyle = color
    context.beginPath()
    context.moveTo(0, yBaseline)
    for (let i = 0; i < waveformData.samples.length; i++) {
      context.lineTo(i, yBaseline - waveformData.samples[i] * yMultiplier)
    }
    context.lineTo(canvas.width, yBaseline)
    context.closePath()
    context.fill()

    // Draw the bottom part
    context.beginPath()
    context.moveTo(0, yBaseline)
    for (let i = 0; i < waveformData.samples.length; i++) {
      context.lineTo(i, yBaseline + waveformData.samples[i] * yMultiplier)
    }
    context.lineTo(canvas.width, yBaseline)
    context.closePath()
    context.fill()
  }

  onWaveformDragStart(event) {
    if (!this.playing) {
      this.play()
    }
    this.playBarProgress = event.offsetX / this.waveformContainerTarget.clientWidth
    this.dragging = true
    this.redrawProgress()
  }

  onWaveformDrag() {
    if (this.dragging) {
      this.playBarProgress = event.offsetX / this.waveformContainerTarget.clientWidth
      this.redrawProgress()
    }
  }

  onWaveformDragEnd(event) {
    event.preventDefault()
    this.dragging = false

    if (this.audioElementTarget.duration) {
      this.audioElementTarget.currentTime = this.playBarProgress * this.audioElementTarget.duration
      this.play()
    }
  }

  pause() {
    this.discardAudioReset()
    if (this.playPromise !== undefined) {
      this.playPromise
        .then((_) => {
          this.audioElementTarget.pause()
        })
        .catch(handleError)
    }

    this.displayPlayButton()
    this.playing = false
    this.audioElementTarget.dataset.playing = false
    this.scheduleAudioReset()
  }

  setButtonDisplayState() {
    if (this.audioElementTarget.paused) {
      this.displayPlayButton()
    } else if (this.audioIsPlaying()) {
      this.displayPauseButton()
    } else {
      this.displayLoadingSpinner()
    }
  }

  audioIsPlaying() {
    return (
      this.audioElementTarget.currentTime > 0 &&
      !this.audioElementTarget.paused &&
      !this.audioElementTarget.ended &&
      this.audioElementTarget.readyState > 2
    )
  }

  displayPauseButton() {
    this.playButtonTarget.style.display = "none"
    this.loadingSpinnerTarget.style.display = "none"
    this.pauseButtonTarget.style.display = "inline"
    this.canvasWrapper2Target.style.display = "block"
  }

  displayLoadingSpinner() {
    this.playButtonTarget.style.display = "none"
    this.loadingSpinnerTarget.style.display = "block"
    this.pauseButtonTarget.style.display = "none"
  }

  displayPlayButton() {
    this.playButtonTarget.style.display = "inline"
    this.loadingSpinnerTarget.style.display = "none"
    this.pauseButtonTarget.style.display = "none"
  }

  scheduleAudioReset() {
    this.audioResetTimeout = setTimeout(() => {
      if (this.playPromise !== undefined) {
        this.playPromise.then(() => {
          this.audioElementTarget.load()
        })
      }
    }, 0)
  }

  discardAudioReset() {
    if (this.audioResetTimeout) {
      clearTimeout(this.audioResetTimeout)
      this.audioResetTimeout = null
    }
  }
}
