import Cookies from "js-cookie"

import { Consent, userHasOptedOutOfCookiesForCategory } from "./consent"
import { removeCookieWithDefaultOptions } from "../utils/cookies"

const experimentCookiePattern = /^(market_experiment(_|s$)|mk_ex$)/

const getNumericValueOfString = (string) =>
  Array.from(string)
    .map((e) => e.charCodeAt(0))
    .reduce((memo, value) => memo + value)

const byNumericValue = (a, b) => {
  const firstElement = getNumericValueOfString(a)
  const secondElement = getNumericValueOfString(b)

  if (firstElement < secondElement) {
    return -1
  }

  if (firstElement > secondElement) {
    return 1
  }

  return 0
}

const removeTrackerStorage = () => {
  const optionsForRemoval = { path: "/" }

  /* eslint-disable-next-line banhammer/no-restricted-functions */
  const cookies = Cookies.get()

  const sortedCookies = Object.keys(cookies).sort(byNumericValue).reverse()

  sortedCookies
    .filter((key) => experimentCookiePattern.test(key))
    .forEach((match) => {
      removeCookieWithDefaultOptions(match, optionsForRemoval)
    })
}

export const handleCookiebotAcceptDeclineEvent = () => {
  if (userHasOptedOutOfCookiesForCategory(Consent.preferences)) {
    removeTrackerStorage()
  }
}

export const handleExperimentOptedOutEvent = () => {
  if (typeof window !== "undefined") {
    window.addEventListener("CookiebotOnAccept", handleCookiebotAcceptDeclineEvent)
    window.addEventListener("CookiebotOnDecline", handleCookiebotAcceptDeclineEvent)
  }
}
