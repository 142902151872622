// We call this during the turbo:load in application.js
// It sets the correct GA experiment variables for us
// This use to be in a stimulus controller, but it was difficult to ensure the stimulus connect() ran before
// the page view event. This made testing harder, and if the order of stimulus controllers in application_layout.html.erb
// changed then it would change when these GA variables were set.

import { Consent, userHasOptedOutOfCookiesForCategory } from "./consent"
import Cookies from "js-cookie"
import ga from "./ga"

export const reportExperimentEnrolmentToGa = () => {
  if (!userHasOptedOutOfCookiesForCategory(Consent.preferences)) {
    let gaValue

    // eslint-disable-next-line banhammer/no-restricted-functions
    const clientCookieValue = Cookies.get("mk_ex")
    const serverCookieValue = document.body.dataset.experimentEnrolments

    if (clientCookieValue === serverCookieValue) {
      // Google Optimize needs the experiment string to be delimited with a ! instead of a *.
      // We convert it here before logging it to Google Analytics.
      gaValue = serverCookieValue.replace(/\*/g, "!")
    }
    if (!gaValue) {
      gaValue = null
    }

    ga("set", "exp", gaValue)
    ga("set", "dimension21", gaValue)
    ga("set", "dimension22", gaValue)
  }
}
