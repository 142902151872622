import { Controller } from "@hotwired/stimulus"
import ga from "../utils/ga"

export default class extends Controller {
  static values = {
    category: String,
  }

  connect() {
    ga("send", {
      hitType: "event",
      eventAction: "view",
      eventCategory: `Elements API;${this.categoryValue}`,
      eventLabel: "loaded",
      nonInteraction: true,
    })
  }
}
