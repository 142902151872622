import { Controller } from "@hotwired/stimulus"
import SsoForm, { en as messages, marketTheme as theme, Features } from "@envato/sso-forms-standalone"

import LocalSession from "../utils/local_session"
import ga from "../utils/ga"

export default class extends Controller {
  static targets = ["signInHeader", "signUpHeader", "modal"]

  static classes = ["loading", "hidden"]

  static values = { recaptchaSiteKey: String }

  connect() {
    const flow = this.data.get("flow") || "signin"

    flow === "signin" ? this.showSignIn() : this.showSignUp()
  }

  renderForm() {
    const config = {
      languageCode: "en",
      flow: this.flow,
      rootElement: "#forms-root",
      ssoHost: this.ssoServerDomain,
      messages,
      autoSignIn: true,
      clientSlug: this.ssoClientKey,
      clientAppId: "market",
      theme,
      features: this.getFeatures(),
      analytics: {
        sendEvent: this.sendGAEvent,
      },
      recaptchaV2SiteKey: this.recaptchaSiteKeyValue,
      onSuccess: this.handleSuccess,
      onError: this.handleError,
      onClickSignUp: () => this.showSignUp(),
      onClickSignIn: () => this.showSignIn(),
    }

    SsoForm(config)

    this.modalTarget.classList.remove(this.loadingClass)
  }

  showSignIn() {
    this.flow = "signin"
    this.signInHeaderTarget.classList.remove(this.hiddenClass)
    this.signUpHeaderTarget.classList.add(this.hiddenClass)

    this.renderForm()
  }

  showSignUp() {
    this.flow = "signup"
    this.signUpHeaderTarget.classList.remove(this.hiddenClass)
    this.signInHeaderTarget.classList.add(this.hiddenClass)

    this.renderForm()
  }

  handleSuccess(token) {
    LocalSession.signIn(token, (message) => console.log(message))
  }

  handleError() {}

  sendGAEvent(event) {
    ga("send", {
      hitType: "event",
      eventCategory: event.category,
      eventAction: event.action,
      eventLabel: event.label,
      eventValue: event.value,
    })
  }

  getFeatures() {
    const featureList = [Features.AppleAuth, Features.FacebookAuth, Features.LoginWithEmail]

    return featureList
  }

  get ssoServerDomain() {
    return document.head.querySelector('meta[name="sso_server_domain"]').content
  }

  get ssoClientKey() {
    return document.head.querySelector('meta[name="sso_client_key"]').content
  }
}
